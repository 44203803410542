@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');

.demo-control {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    background: #cefded;
    color: #fff;
}

body {
    background: #fff !important;
    font-family: Jost,sans-serif !important;
    color: #534545 !important;
}

li.chakra-breadcrumb__list-item {
  color: #fff !important;
}

.logo-bg img {
    max-width: 350px;
    height: auto;
    border-bottom: 1px solid #fff;
}

figcaption {
    color: #534545;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
}

p.chakra-text {
    color: #534545 !important;
}

.polygon img{
  max-height: 214px;
}

.logo-bg {
    border-bottom: 1px solid #fff;
    background-color: #9EA3F5;
}

.logo-bg .chakra-text {
  color: #fff !important;
}


/* Button */
.btn {
  display: inline-block;
  padding: 0 2em;
  background: #fff;
  border: none;
  color: #534545;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  transition: all 280ms ease-out;
  -webkit-user-select: none;
  user-select: none;
}

.btn:hover {
  background: #CCDCFD;
  color: #fff;
}

#claimbtn {
    float: right;
    position: absolute;
    right: 40px;
}

img.chakra-image.css-kddds2,
img.product-image {
    border: 15px solid #000019;
    border-radius: 0px !important;
    padding: 20px;
    box-shadow: rgb(50 50 93 / 25%) 10px 10px 37px -12px inset, rgb(0 0 0 / 30%) 0px 18px 12px -10px inset;
}
